<template>
  <div>
    <content-box
      title="Kindergarten-3rd Grade Program"
      class="col-lg-9 col-xl-7"
    >
      <img
        class="d-block w-50 mx-auto mb-2 float-md-right ml-md-3"
        style="max-width: 400px"
        src="/img/programs/k-3Program.jpg"
        alt="K-3rd Program"
      />

      <div class="text-justify">
        <p>
          Focusing on kids that are just being introduced to the sport, our
          Kindergarten through 3rd grade program teaches the fundamentals of
          folkstyle wrestling. The program is designed to be structured and fun,
          incorporating lots of wrestling drills and games.
        </p>
        <p>
          This program meets {{ littlesPracticeFrequency }} a week;
          {{ littlesPracticeDays }} evenings from {{ littlesPracticeTimes }}.
          Wrestlers are grouped by age, weight, experience, and ability. For
          older or more athletic wrestlers, there is an opportunity to switch to
          the 4th through 8th grade program pending approval from the coaching
          staff.
        </p>
        <p>
          Participants should wear "gym clothes" to practice. For children under
          8, at least one parent or guardian needs to be present during
          practice.
        </p>
        <p>
          There will be opportunities to compete against other towns and in
          tournaments.
        </p>

        <registration-button></registration-button>
      </div>
    </content-box>

    <content-box title="4th-8th Grade Program" class="col-lg-9 col-xl-7">
      <img
        class="d-block w-50 mx-auto mb-2 float-md-left mr-md-3"
        style="max-width: 400px"
        src="/img/programs/4-8Program.jpg"
        alt="4-8th Program"
      />

      <div class="text-justify">
        <p>
          Our 4th - 8th grade program is open to both beginner and experienced
          wrestlers. The program builds upon the fundamentals of folkstyle
          wrestling to incorporate more advanced technique and maneuvers.
          Beginners will have plenty of opportunity to catch up and learn good
          fundamentals.
        </p>
        <p>
          This program meets {{ bigsPracticeFrequency }} a week;
          {{ bigsPracticeDays }} evenings from {{ bigsPracticeTimes }}.
          Wrestlers are grouped by age, weight, experience and ability. For
          younger or less athletic wrestlers, there is an opportunity to switch
          to the Kindergarten through 3rd grade practice if the coaches feel the
          wrestler is not progressing.
        </p>
        <p>Participants should wear form fitting "gym clothes" to practice.</p>
        <p>
          There are opportunities to compete against other towns and in
          tournaments. For some, the season will extend beyond three months in
          order to compete in post season tournaments.
        </p>

        <registration-button></registration-button>
      </div>
    </content-box>
  </div>
</template>

<script>
import registrationButton from '@/components/registration/registrationButton'
import contentBox from '@/components/contentBox'

export default {
  name: 'programs',
  components: {
    registrationButton,
    contentBox,
  },
  data() {
    return {
      //eventually fetch this from an api call
      programs: {
        littles: {
          program: 'K - 3rd Grade Program',
          location: 'Pentucket Lake Elementary School',
          dates: {
            start: 'November',
            end: 'January',
          },
          days: ['Monday', 'Tuesday', 'Thursday'],
          time: {
            start: '5:30',
            end: '6:30',
          },
        },
        bigs: {
          program: '4th - 8th Grade Program',
          location: 'Pentucket Lake Elementary School',
          dates: {
            start: 'November',
            end: 'February',
          },
          days: ['Monday', 'Tuesday', 'Thursday'],
          time: {
            start: '6:30',
            end: '8:00',
          },
        },
      },
    }
  },
  computed: {
    littlesPracticeFrequency() {
      let cardinality = [
        'once',
        'twice',
        'three',
        'four',
        'five',
        'six',
        'seven',
      ]
      let frequency = this.programs.littles.days.length

      let practiceFrequency = cardinality[frequency - 1]

      if (frequency > 2) {
        practiceFrequency += ' times'
      }

      return practiceFrequency
    },
    littlesPracticeDays() {
      let practiceDays = ''

      //use slice here to clone the array, that way the splice below doesn't affect our data set
      let days = this.programs.littles.days.slice()

      if (days.length > 0) {
        if (days.length > 1) {
          let lastDay = days.splice(days.length - 1, 1)
          practiceDays = days.join(', ') + ' & ' + lastDay[0]
        } else {
          practiceDays = days[0]
        }
      }

      return practiceDays
    },
    littlesPracticeTimes() {
      return (
        this.programs.littles.time.start +
        '-' +
        this.programs.littles.time.end +
        'p'
      )
    },

    bigsPracticeFrequency() {
      let cardinality = [
        'once',
        'twice',
        'three',
        'four',
        'five',
        'six',
        'seven',
      ]
      let frequency = this.programs.bigs.days.length

      let practiceFrequency = cardinality[frequency - 1]

      if (frequency > 2) {
        practiceFrequency += ' times'
      }

      return practiceFrequency
    },
    bigsPracticeDays() {
      let practiceDays = ''

      //use slice here to clone the array, that way the splice below doesn't affect our data set
      let days = this.programs.bigs.days.slice()

      if (days.length > 0) {
        if (days.length > 1) {
          let lastDay = days.splice(days.length - 1, 1)
          practiceDays = days.join(', ') + ' & ' + lastDay[0]
        } else {
          practiceDays = days[0]
        }
      }

      return practiceDays
    },
    bigsPracticeTimes() {
      return (
        this.programs.bigs.time.start + '-' + this.programs.bigs.time.end + 'p'
      )
    },
  },
}
</script>

<style scoped></style>
